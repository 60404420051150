import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';
import t, { _t } from '../utils/translate';
import Layout from '../components/Layout/Layout';
import PageHeader from '../components/PageHeader/PageHeader';
import InfoBlock from '../components/Blocks/InfoBlock/InfoBlock';
import Vector from '../components/Vector/Vector';
import TwoColumnsWrapper from '../components/TwoColumns/TwoColumnsWrapper';
import ContactInfo from '../components/ContactInfo/ContactInfo';
import styles from '../style/om-oss.module.scss';
import Body from '../components/Body/Body';
import SEO from '../components/seo';

import useSetLocale from '../hooks/useSetLocale';

const About = ({ data, location }) => {
  useSetLocale();

  const {
    title,
    intro,
    imageOrVideo,
    ticketPrices,
    contactInfo,
    body,
    bigGroups,
    seo,
  } = data.sanityAbout;

  return (
    <Layout dark={true} location={location}>
      <SEO
        title={`${_t(title)}`}
        description={seo && seo.description ? seo.description : null}
        image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <PageHeader
        dark={true}
        title={title}
        intro={intro}
        asset={imageOrVideo}
      />

      <div className={styles.shortcuts}>
        <ul className={styles.listOuter}>
          {contactInfo.length || ticketPrices.length ? (
            <li>
              <Vector
                type={'arrow-down'}
                colour={'#FFC600'}
                className={styles.arrowInner}
              />
            </li>
          ) : null}
          {contactInfo.length > 0 && (
            <li>
              <a href={`#${t('contactInfo')}`}>{t('contactInfo')}</a>
            </li>
          )}
          {ticketPrices.length > 0 && (
            <li>
              <a href={`#${t('ticketPrices')}`}>{t('ticketPrices')}</a>
            </li>
          )}
        </ul>
      </div>

      <div className={classNames(styles.outer, 'page-padding')}>
        <TwoColumnsWrapper>
          <Body dark={true} bodyParts={body} />

          {contactInfo.map((contact, index) => (
            <div
              className={styles.contactOuter}
              key={index}
              id={t('contactInfo')}
            >
              <ContactInfo
                dark={true}
                location={location}
                aboutPage={true}
                {...contact}
              />
            </div>
          ))}

          {ticketPrices.map((ticket, index) => {
            return (
              <div
                className={styles.infoOuter}
                key={index}
                id={t('ticketPrices')}
              >
                <InfoBlock location={location} {...ticket} />
              </div>
            );
          })}

          {bigGroups && (
            <div className={styles.bigGroupsOuter}>
              <Img
                alt={bigGroups.image[0].alt}
                fluid={bigGroups.image[0].asset.fluid}
              />
              <div className={styles.textOuter}>
                <h3 className={classNames(styles.headline, 'headline-40')}>
                  {_t(bigGroups.headline)}
                </h3>
                <p className={classNames(styles.text, 'body-text-s')}>
                  {_t(bigGroups.text)}
                </p>
                <a
                  className={classNames(styles.requestLink, 'body-text-s')}
                  href={bigGroups.link.linkTo[0].href}
                >
                  <Vector type="mail" colour="#fff" />
                  {_t(bigGroups.link.text)}
                </a>
              </div>
            </div>
          )}
        </TwoColumnsWrapper>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    sanityAbout {
      title {
        nb
        en
        _type
      }
      imageOrVideo {
        ... on SanityVideoUpload {
          ...HdVideoQuery
        }
        ... on SanityMediaImage {
          ...HeadImageQuery
        }
      }
      intro {
        nb
        en
        _type
      }
      body: _rawBodyContent(resolveReferences: { maxDepth: 10 })
      ticketPrices {
        headline {
          nb
          en
          _type
        }
        textField {
          nb
          en
          _type
        }
        content {
          ... on SanityTicketPrices {
            ...InfoTicketQuery
          }
          ... on SanityLocation {
            ...InfoLocationQuery
          }
          ... on SanityHours {
            ...InfoHoursQuery
          }
        }
        _type
      }
      contactInfo: _rawContactInfo(resolveReferences: { maxDepth: 10 })
      bigGroups {
        _type
        headline {
          nb
          en
          _type
        }
        image {
          ... on SanityArticleVideoEmbed {
            _key
            _type
            videoUpload {
              asset {
                url
              }
            }
          }
          ... on SanityMediaImage {
            _key
            _type
            alt
            asset {
              fluid(maxWidth: 660, maxHeight: 420) {
                ...GatsbySanityImageFluid
              }
              _id
            }
          }
        }
        link {
          text {
            nb
            en
            _type
          }
          _type
          linkTo {
            ... on SanityLinkHref {
              _key
              _type
              href
            }
          }
        }
        text {
          nb
          _type
          en
        }
      }
      seo {
        ...SeoQuery
      }
    }
  }
`;

export default About;
