import React from 'react';
import styles from './body.module.scss';
import Text from './Blocks/Text/Text';
import Accordion from './Blocks/Accordion/Accordion';

export default function Body({ dark, bodyParts }) {
  // console.log('blocks', blocks);

  return bodyParts.map(block => {
    switch (block._type) {
      case 'articleText':
        return (
          <div className={styles.outer} key={block._key}>
            <Text dark={dark} content={block.content} />
          </div>
        );
      case 'articleAccordion':
        return (
          <Accordion
            dark={dark}
            headline={block.headline}
            content={block.content}
            key={block._key}
          />
        );

      default:
        return null;
    }
  });
}
