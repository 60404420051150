import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './TwoColumns.module.scss';

export default function TwoColumnsWrapper({ children }) {
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWindowSize);
    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
  }, []);

  const setWindowSize = () => {
    setWidth(window.innerWidth);
  };
  if (width > 768) {
    return (
      <div className={styles.twocolumnsOuter}>
        <div className={classNames(styles.column, styles.columnEven)}>
          {children.map((child, index) => {
            if (index % 2 === 0) {
              return child;
            } else {
              return null;
            }
          })}
        </div>
        <div className={classNames(styles.column, styles.columnOdd)}>
          {children.map((child, index) => {
            if (index % 2 === 1) {
              return child;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.twocolumnsOuter}>
        <div className={classNames(styles.column, styles.mobile)}>
          {children}
        </div>
      </div>
    );
  }
}
